@import "normalize.css";

html, body {
	overflow: hidden;
	font-family: Roboto, sans-serif;
	overscroll-behavior-y: none;
	margin: 0;
	height: 100%;
}

#root{
	height: 100%;
	display: flex;
	overscroll-behavior-y: none;
	background-image: url("./resources/esb/sky.png");
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #0B1631;
}

a {
  color: inherit; 
	text-decoration: inherit;
	display: inherit;
}

h1, h2, h3, p, span {
	user-select: none;
}

* {
	touch-action: manipulation;
}

button {
	padding: 0;
	border: none;
	font: inherit;
	color: unset;
	background-color: transparent;
	cursor: pointer;
	text-decoration: unset;
}

main.simon {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-grow: 1;
}

@media only screen and (min-width: 768px) {	
	main.simon{	
		width: 100%;		
		margin: auto;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}

/* @media only screen and (min-width: 1200px) {	
	main.simon{	
		width: 100%;
		max-width: 600px;
		max-height: 800px;
		margin: auto;
		justify-content: center;	
		height: 100%;
	}
} */