.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 2em;
  background-color: black;
  color: white;
}

.main img {
	margin: 10px 0;
}

.main > a {
  margin-top: 40px;
  width: 100%;
  max-width: 400px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {	
	.rules {
    width: 50%;
    margin: auto;
    color: #00FF00;
    font-size: 18px;
    margin-bottom: 28px;
  }

  .title{
    width: 50%;
    margin: auto;
  }

}

@media only screen and (min-width: 1200px) {	
	.rulesContainer{	
    max-width: 600px;
  }
  .rules {
    margin: auto;
    color: #00FF00;
    font-size: 24px;
    margin-bottom: 28px;
  }
}




.rules {
  margin: auto;
  color: #00FF00;
  font-size: 18px;
  margin-bottom: 28px;
}

