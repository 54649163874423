.ratingContainer {
	width: 100%;
	border-radius: 8px;
	display: inline-flex;
	text-transform: uppercase;
	line-height: 100%;
}

.ratingButton{
	width: 50%;
}

.ratingTitle{
	width: 100%;
	justify-content: center;
}

.ratingBox{
	padding: 2%;
	border: 1px solid white;
	border-radius: 10px;
}